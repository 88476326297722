.text-message-modal {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 136.79%;
    text-align: center;
    color: rgba(0, 0, 0, 0.75);
}
.btn-cancelled {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 154.4%;
    color: #02B4C0;
    padding: 15px 1.5rem;
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
}
.form-label-modal-custom {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #06152B;

}
.form-control-modal-custom {
    background: #F2F2F2;
    border: none;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14.22px;
    line-height: 17px;
    color: #06152B;
    padding: 20px;
}
.form-select-modal-custom {
    background: #F2F2F2;
    border: none;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14.22px;
    line-height: 17px;
    color: #06152B;
    padding: 20px;
    background: url(../../../assets/icons/down.png) 99% 47% / 13px no-repeat #fff !important;

}
.text-profil-user-modal {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 154.4%;
    color: #919EAB;
}
.title-modal-send-message {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 154.4%;
    color: #000000;
}
.title-modal-send-message {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.btn-send-message-modal {
    background: #02B4C0;
    border-radius: 10px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 154.4%;
    color: #FFFFFF;
    padding: 15px;
}
.btn-send-message-modal:hover {
    background: #fff;
    border: 1px solid #02B4C0;
    color: #02B4C0;
}
.btn-send-message-modal.disabled {
    background: #E0E0E0;
    border-radius: 10px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 154.4%;
    color: #FFFFFF;
    padding: 15px;
}
.modal-content-message {
    border-top: 7px solid #02B4C0;
}
.user-for-send-message {
    list-style: none;
}

.custom-bloc-details {
    height: 350px !important;
}
.no-style-btn {
    border: none;
    background-color: #aeaeae17;
    color: #02B4C0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.text-response-card {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 151.7%;
    color: rgba(0, 0, 0, 0.75);
    text-align: left;
}
.text-comment-facture-table {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400 !important;
    font-size: 15px !important;
    line-height: 151.7%;
    color: rgba(0, 0, 0, 0.75);
    text-align: left;
}
.textarrea-comment-custom {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #868686;
    background: #FFFFFF;
    border: 2px solid #06ABB6;
    border-radius: 10px;
    padding: 15px;
}
.content-info-facture-comptabilite-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-bottom: 1px solid rgba(196, 196, 196, 0.15);
}
.content-info-facture-comptabilite-item:last-child {
    border-bottom: none;
}
.info-facture-comptabilite-libelle {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 18px !important;
    color: #06ABB6 !important;
    margin-bottom: 0;
}
.info-facture-comptabilite-value {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 21px !important;
    color: rgba(0, 0, 0, 0.75) !important;
    margin-bottom: 0;
}
.content-montant-total-facture-modal {
    background: #EAEAEA;
}
.info-facture-comptabilite-libelle-montant {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700 !important;
    font-size: 20px !important;
    line-height: 30px;
    color: #06ABB6 !important;
    margin-bottom: 0;
}
.info-facture-comptabilite-value-montant {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600 !important;
    font-size: 26px !important;
    line-height: 39px;
    color: rgba(0, 0, 0, 0.75) !important;
    margin-bottom: 0;
}
.content-document-facture {
    display: flex;
    align-items: center;
    flex-direction: column;
}
.content-doc-facture-item {
    background: #FFFFFF;
    box-shadow: 0px 3.20494px 16.0247px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    padding: 15px;
}
.btn-download-doc-facture {
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 15px;
    text-align: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: rgba(1, 2, 53, 0.5);
    padding: 15px;
}
.btn-download-doc-facture svg {
    color: #06ABB6;
}
.btn-download-doc-facture:hover {
    background: #06ABB6;
    color: #fff;
}
.btn-download-doc-facture:hover svg {
    color: #fff;
}
img.img-doc-facture {
    width: 52.05px;
    height: 42.37px;
}

.form-check-input:checked {
    background-color: #06ABB6 !important;
    border-color: #06ABB6 !important;
    width: 20px;
    height: 20px;
}

.permissions-checkbox {
    display: flex;
    align-items: center;
    padding: 0.375em 0.75em 0.375em 0.375em;
    border-radius: 10px;
    transition: 0.25s ease;
    border: 1px solid #E1E1E1;
    height: 59px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #868686;
}