.tabs-list {
	display: flex;
	align-items: center;
	gap: 3rem;
}
.tab-item {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 120%;
	color: #001b4f;
	list-style: none;
	position: relative;
}
.tab-item.active::before {
	content: "";
	height: 3px;
	width: 100%;
	display: block;
	background: linear-gradient(90deg, #02b4c0 0%, #158a91 100%);
	position: absolute;
	bottom: -8px;
	left: 0;
}

.min-h-80 {
	min-height: 80vh;
}
.categories-tab h3 {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 19px;
	line-height: 12px;
	letter-spacing: 0.2px;
	color: #000000;
}
.cat-item {
	background: #f7f9f9;
	border-radius: 5px;
	padding: 8px 10px;
}
.cat-item h5 {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 12px;
	letter-spacing: 0.2px;
	color: #333333;
	margin: 0;
}
.btn-delete-cat {
	background-color: transparent;
	border: none;
	color: #fa1414;
	font-size: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.input-file-control {
	background: #f2f2f2;
	border-radius: 5px;
	width: 100%;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	color: #ababab;
	padding: 20px;
}
