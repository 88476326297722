.auth-component {
	background: #EBF0F0;
}
.auth-right-side-container {
    padding: 220px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.auth-form-title {
	font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    line-height: 42px;
    letter-spacing: 0.1em;
    color: #696969;
	font-size: clamp(30px, 1vw, 36px);
	text-align: center;
	white-space: nowrap;
}

.auth-form-group-login {
    width: 100%;
    background: #FFFFFF;
    border-radius: 10px;
    border: 1px solid #02B4C0;
}

.auth-form-control {
	background: #fff;
	border-color: #fff !important;
	padding: 20px 15px;
	font-family: 'Poppins';
    font-style: normal;
    font-weight: 275;
    font-size: 14px;
    line-height: 21px;
    color: rgba(0, 0, 0, 0.7);
    border-radius: 10px;
}

.input-group-text-custom {
    background: #FFFFFF;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 50px;
    color: #02B4C0;
    border: none;
    border-radius: 10px !important;
}
.form-group.auth-form-group {
	margin-bottom: 40px;
}

.forget-password-link {
	font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #333333;
	text-decoration: none;
}
.forget-password-link:hover {
	text-decoration: none;
    color: #02B4C0;
    font-weight: bold;
}
.auth-form-message {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #333333;
}

.auth-submit-btn {
	background: linear-gradient(90deg, #02B4C0 0%, #158A91 100%);
	border-radius: 10px;
	width: 100%;
	padding: 15px;
	font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.1em;
	text-align: center;
	color: #ffffff;
}
.auth-submit-btn:hover {
	background: #fff;
	color: #02B4C0;
    border: 1px solid #02B4C0;
	text-decoration: none;
}

.auth-submit-btn-red {
	background: linear-gradient(90deg, #D62912 0%, #D62912 100%);
	border-radius: 10px;
	width: 100%;
	padding: 15px;
	font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.1em;
	text-align: center;
	color: #ffffff;
}
.auth-submit-btn-red:hover {
	background: #fff;
	color: #D62912;
    border: 1px solid #D62912;
	text-decoration: none;
}

.auth-left-side-container {
    height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.auth-left-side-col {
	background: linear-gradient(90deg, #02B4C0 0%, #158A91 100%);
}

.auth-row.row .auth-col {
	padding: 0 !important;
}

.form-control:focus {
	box-shadow: none !important;
}

.fixed-after-header {
	margin-top: 4%;
}
