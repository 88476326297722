.admin-table table thead tr th {
	font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 50px;
    color: #010235;
}
.admin-table table tbody tr td,
.admin-table table tbody tr td p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.7);

}
.admin-table .table thead tr th,
.admin-table .table tbody tr td {
	text-align: left;
}
.admin-table .table {
	border: transparent;
}
.admin-table table tbody tr {
	background: #F8F8F8;
	border-bottom: 15px solid #fff;
}
.table > :not(:first-child) {
	border-top: none !important;
}
.table thead tr th,
table tbody tr td {
	padding: 20px 10px !important;
	vertical-align: middle;
}

.react-bootstrap-table table {
	table-layout: auto;
}
.btn-send-rappel {
    background: #1F3CAD;
    border-radius: 10px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 154.4%;
    color: #FFFFFF;
    padding: 12px;
}
.btn-see-list, .btn-see-list:hover {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #1AA981;
    padding: 10px;
}
.btn-action-modal-icon, .btn-action-modal-icon:hover {
    color: #158A91;
    border: none;
    background: rgba(2, 180, 192, 0.22);
    width: 30px;
    height: 30px;
    padding: 0;
    font-size: 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.btn-action-icon-delete, .btn-action-icon-delete:hover {
    color: #FA1414;
    border: none;
    background: #fa14141a;
    width: 30px;
    height: 30px;
    padding: 0;
    font-size: 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.btn-action-icon-chat, .btn-action-icon-chat:hover {
    color: #14AE5C;
    border: none;
    background: #C1E6D3;
    width: 30px;
    height: 30px;
    padding: 0;
    font-size: 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.btn-action-icon-edit, .btn-action-icon-edit:hover {
    color: #E73628;
    border: none;
    background: #e7352835;
    width: 30px;
    height: 30px;
    padding: 0;
    font-size: 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center; 
}
.form-select-table-custom {
    background: #BDBDBD;
    border-radius: 10px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #4F4F4F;
    background: url(../../../../assets/icons/chevron-down.png) 92% 47% / 13px no-repeat #BDBDBD !important;

}

.with-tooltip {
    position: relative;
  }
  
  .btn-action-modal-icon::after {
    content: attr(data-tooltip-content);
    padding: 8px;
    border-radius: 4px;
    position: absolute;
    top: 74%;
    right: 50%;
    left: 50%;
    width: max-content;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: #158A91;
    opacity: 0;
    visibility: hidden;
    transform: translate(-50%, 18px) scale(0.8);
    transition: visibility, opacity, transform 200ms;
  }
  .btn-action-icon-delete::after {
    content: attr(data-tooltip-content);
    padding: 8px;
    border-radius: 4px;
    position: absolute;
    top: 74%;
    right: 50%;
    left: 50%;
    width: max-content;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: #FA1414;
    opacity: 0;
    visibility: hidden;
    transform: translate(-50%, 18px) scale(0.8);
    transition: visibility, opacity, transform 200ms;
  }
  .btn-action-icon-chat::after {
    content: attr(data-tooltip-content);
    padding: 8px;
    border-radius: 4px;
    position: absolute;
    top: 74%;
    right: 50%;
    left: 50%;
    width: max-content;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: #14AE5C;
    opacity: 0;
    visibility: hidden;
    transform: translate(-50%, 18px) scale(0.8);
    transition: visibility, opacity, transform 200ms;
  }
  .btn-action-icon-edit::after {
    content: attr(data-tooltip-content);
    padding: 8px;
    border-radius: 4px;
    position: absolute;
    top: 74%;
    right: 50%;
    left: 50%;
    width: max-content;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: #E73628;
    opacity: 0;
    visibility: hidden;
    transform: translate(-50%, 18px) scale(0.8);
    transition: visibility, opacity, transform 200ms;
  }
  .with-tooltip:hover::after {
    visibility: visible;
    opacity: 1;
    transform: translate(-50%, 0);
  }

.pagination {
    --bs-pagination-border-width: none !important;
    gap: 10px;
}
.page-item:first-child .page-link, .page-item:last-child .page-link {
    border: none !important;
}
.page-item.active .page-link {
    background: #02B4C0;
    color: #fff;
}
.dropdown-toggle {
    background: #02B4C0;
    color: #fff;
}
.dropdown-item {
    background: #02B4C0;
    color: #fff;
}
li.dropdown-item a {
    text-decoration: none;
    color: #fff;
}
.dropdown-item:hover {
    background: #5C636A;
    color: #fff;
}
.page-item .page-link {
    border-radius: 10px;
    border: 1px solid #9E9E9E;
    background: #fff;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.7);
}
.statut-comment-btn {
    position: absolute;
    top: -1%;
    right: -1%;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    background: #C71515;
    border-radius: 50%;
    color: #FFFFFF;
    width: 12px;
    height: 12px;
}
.statut-facture-valide {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    background: #06B696;
    border-radius: 5px;
    color: #FFFFFF !important;
    padding: 10px;
    white-space: nowrap;
}
.statut-facture-a-traiter {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    background: #D62912;
    border-radius: 5px;
    color: #FFFFFF !important;
    padding: 10px;
    white-space: nowrap;
}