body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media (min-width: 320px) and (max-width: 480px) {
  .couverture-title {
    padding: 0 !important;
  }
  .page-couverture-actions-container {
    flex-direction: column;
  }
  .fast-facture-btn-success {
    letter-spacing: 0 !important;
  }
  .subscribe-newsletters-btn-container,
  .play-video-btn-container {
    margin: 15px !important;
  }
  .fast-facture-btn-default {
    text-align: center;
  }
  .play-video-btn-container {
    display: flex;
    justify-content: center;
  }
}

@media screen and (min-width: 1900px) {
  .couverture-title {
    font-size: 100px !important;
    line-height: 110px !important;
  }
  .coming-soon-textual {
    font-size: 55px !important;
  }
  .page-couverture-title {
    margin: 30px 15px !important;
  }
  .fast-facture-btn-success {
    padding: 35px 40px !important;
    font-size: 30px !important;
  }
  .fast-facture-btn-default {
    padding: 35px 40px !important;
    font-size: 40px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
  .play-video-icon {
    width: 100px !important;
    height: 100px !important;
    display: flex !important;
    padding: 25px !important;
  }
  .subscribe-newsletters-btn-container {
    display: flex !important;
    align-items: center !important;
  }
  .app-logo {
    width: 20% !important;
  }
}
.custom-pagination-component {
  padding-left: 3%;
  padding-right: 2%;
}
.fast-facture-tabs-container .tabs-container {
  padding-left: 4%;
  padding-right: 4%;
  padding-bottom: 2%;
}
.fast-facture-tabs-container {
  margin-left: 1%;
}
.fast-facture-locataire-histo-row {
  margin-left: 1%;
}
.msg-user-infos-container {
  padding-left: 15px;
}

.modal:nth-of-type(even) {
  z-index: 1052 !important;
}

.modal-backdrop.show:nth-of-type(even) {
  z-index: 1051 !important;
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
  content: "";
}
