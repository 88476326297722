.statut-notif-super-admin {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #BBBBBB;
    margin-bottom: 0;
}
.text-notif-super-admin {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: rgba(0, 0, 0, 0.5);
    margin-bottom: 0;
}
.text-date-notif-super-admin {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: rgba(0, 0, 0, 0.5);
    margin-bottom: 0;
}