.bg-primary {
  background: linear-gradient(90deg, #02b4c0 0%, #158a91 100%);
}

.logo-app {
  width: 120px;
  height: auto;
}

.sigCanvas {
  border: 1px solid #ccc;
  height: 300px;
  width: 100%;
}
.btn-primary {
  background-color: #158a91;
  border: 1px solid #158a91;
  color: #fff;
}
.btn-outline-primary {
  background-color: #fff;
  border: 1px solid #158a91;
  color: #158a91;
}
