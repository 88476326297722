.btn-add-theme {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: #FFFFFF;
    background: linear-gradient(90deg, #02B4C0 0%, #158A91 100%);
    border-radius: 5px;
    padding: .5rem 1rem;
}
.btn-add-theme:hover {
    border: 1px solid #02B4C0;
    color: #02B4C0;
    background: #fff;
}
.tabs-container {
    display: flex;
    background: #FFF;
    padding: 30px 20px 2px 20px;
    margin-top: -16px;
}

.tabs-container {
	display: flex;
	flex-wrap: wrap;
	gap: 2rem;
}

.tabs-container li {
	list-style: none;
	font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    display: flex;
    align-items: center;
    color: #000000;
	transition: all ease-in-out 300ms;
    padding-bottom: 10px;
}
li.tab-active-item {
	color: #158A91;
	font-weight: 700;
    border-bottom: 2px solid #158A91;
}
.row-info-perso-user {
    border-bottom: 1px solid rgba(196, 196, 196, 0.5);
    padding: 20px;
}
.row-info-perso-user:last-child {
    border-bottom: none;
}
.text-info-user-libelle {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.4px;
    text-transform: capitalize;
    color: #666666;
    margin-bottom: 0;
}
.text-info-user-value {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.4px;
    text-transform: capitalize;
    color: #666666;
    margin-bottom: 0;
}