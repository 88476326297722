.body-background-dashboard {
    background: #EFF1F7;
}
.admin-page-container {
    margin-right: 3%;
    margin-left: 5%;
}
.content-position-after-header {
    margin-top: 5%;
}
.title-view-dashboard{
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    color: #000000;
    margin-bottom: 15px;
}
.card-statistics {
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 15px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-decoration: none !important;
}
.icon-card-statistics {
    color: rgba(255, 255, 255, 0.432);
    position: absolute;
    right: 40px;
    top: 20px;
    width: 50px;
}

.chiffre-card-statistics {
    font-family: "Work Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 50px;
    line-height: 59px;
    color: #ffffff;
}

.titre-card-statistics {
    font-family: "Work Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
}

.stat-conteny p {
    color: #ffffff;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
  }
  .parent-label-card-statistics {
    font-weight: 800;
  }
  .child-label-card-statistics {
    margin-top: 5px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 300 !important;
    font-size: 11px !important;
    line-height: 13px;
    color: #FFFFFF;
  }

  .item-stat-dashboad-view{
    background: #FFFFFF;
    padding: 10px 20px;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 30px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}

.titre-item-stat-dashboad-view{
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #808191;
}
.chiffre-item-stat-dashboad-view{
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    color: #11142D;
}
.chart-item-admin-title {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    color: #000000;
}
.content-stat-dashboard-view {
    display: flex;
    align-items: center;
    width: 100%;
}
.content-text-item-stat-dashboad-view {
    width: 100%;
}

.col-left-dashboard {
    padding: 40px 0;
}
.container-table-locataires-non-paye .react-bootstrap-table {
    padding-left: 0;
}
.title-card-dashbord-view {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #000000;
}
.litige-card-item-dashboard-view {
    display: flex;
    gap: 1rem;
}
.litige-text-dashboard-view {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #000000;
}
.circle-item-green {
    background: #fff;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 4px solid #1AA981;
}
.circle-item-blue {
    background: #fff;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 4px solid #1F3CAD;
}
.circle-item-yellow {
    background: #fff;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 4px solid #FCAE3B;
}
.vertical-line {
    border-left: 2px solid #B8BED9;
    display: inline-block;
    height: 45px;
    margin: 10px 10px;
}
.title-info-dashboard-view {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
}
.text-date-info-dashboard-view {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #3D3D3D;
}
.text-chiffre-info-dashboard-view {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #1F3CAD;
}
.content-info-item {
    background: #FFFFFF;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    padding: 15px 20px;
}
.col-right-dashboard-view {
    margin-top: 1%;
}
.content-img-notif-requete svg {
    font-size: 35px;
}
.content-img-notif-requete {
    position: absolute;
    top: 0;
    left: 0;
    background: #FCAE3B;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 10px 0px;
    color: #fff;
    padding: 10px;
}
.btn-see-more {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #158A91 !important;
    background: transparent;
    padding: 15px;
    border: none;
}
.btn-see-more:hover {
    text-decoration: none !important;
    border: none;
    color: #158A91;
}
.icon-see-more {
    color: #158A91;  
}
.dashboard-view-hiddenable-notification-section {
    position: absolute;
    left: 0;
    background: #ffffff;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    padding: 10px;
    top: 56%;
    width: 100%;
  }
  .dashboard-view-hiddenable-notification-section:after {
    content: "";
    width: 0;
    height: 0;
    border-left: 17px solid transparent;
    border-right: 17px solid transparent;
    border-bottom: 20px solid #ffffff;
    position: absolute;
    left: 1%;
    top: -5%;
  }
  .admin-page-title-header-container {
    margin-top: 30px;
  }
  .fast-facture-dash-header-titre {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #11142D;
  }