.tab-dash-admin-filter-form {
    display: flex;
    align-items: center;
}
.tab-dash-admin-filter-label {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 15px;
    text-align: center;

    color: rgba(0, 0, 0, 0.6);
}
.tab-dash-admin-filter-input {
    width: 200px;
    height: 39px;
    left: 377px;
    top: 223px;
    background: #F7F7F7;
    border-radius: 10px;
    border: none;

    font-family: 'Raleway';
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 15px;

    color: rgba(0, 0, 0, 0.6);
}
.tab-dash-admin-filter-input::placeholder {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 200;
    font-size: 14px;
    line-height: 16px;
    color: #252733;
}
.btn-export-info-tabs {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: #828282;
    border: 1px solid #828282;
    border-radius: 5px;
    background: #fff;
}
.text-date-facture {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: rgba(51, 51, 51, 0.5);
}
.title-info-detail-facture {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #333333;
}
.text-detail-facture-libelle {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #5A5A5A;
}
.text-detail-facture-value {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
}
.text-detail-facture-montant-libelle {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
}
.text-montant-total-facture {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #4F9FD1;
}