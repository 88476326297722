.filtre-select {
	appearance: none;
	background: url(../../../../assets/icons/down.png) 92% 47% / 13px no-repeat #f7f7f7 !important;
	background: #f7f7f7;
	border-radius: 10px;
	border: none;
	font-family: "Raleway";
	font-style: normal;
	font-weight: 300;
	font-size: 13px;
	line-height: 15px;
	color: rgba(0, 0, 0, 0.6);
}
.text-entreprise-user-comptabilite {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	color: rgba(0, 0, 0, 0.6);
}

.content-commentaire {
	height: 200px;
	overflow-y: scroll;
	background: #fff;
	padding: 20px;
}
.circle-graph-container {
	background: #ffffff;
	box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
	border-radius: 10px;
}
.filter-item {
	background: #ffffff;
	font-family: "Poppins";
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 20px;
	color: #666666;
	list-style: none;
	padding: 10px;
	box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
}
.filter-item.active{
    background-color: #06ABB6;
    color: #fff;
}
.graph-title {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 500;
	font-size: clamp(12px, 2vw,15px);
	line-height: 15px;
	text-transform: uppercase;
	color: #000000;
}

