.container-radio-input {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 20px 0;
}
.form-radio-input {
    display: flex;
    flex-wrap: wrap;
}
.radio-input label {
    margin-left: 10px;
    margin-right: 10px;
}
.radio-input label input {
    position: absolute;
    left: -9999px;
}
.radio-input label input:checked + span {
    background-color: #fff;
    border: 1px solid #12C28D;
    box-sizing: border-box;
    border-radius: 10px;
}
.radio-input label input:checked + span:before {
    box-shadow: inset 0 0 0 0.4375em #12C28D;
}
.radio-input label span {
    display: flex;
    align-items: center;
    padding: 0.375em 0.75em 0.375em 0.375em;
    border-radius: 10px;
    transition: 0.25s ease;
    border: 1px solid #E1E1E1;
    height: 59px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #868686;

}
.radio-input label span:hover {
    background-color: #fff;
    border: 2px solid #12C28D;
}
.radio-input label span:before {
    display: flex;
    flex-shrink: 0;
    content: "";
    background-color: #fff;
    width: 1em;
    height: 1em;
    margin-right: 0.375em;
    transition: 0.25s ease;
    box-shadow: inset 0 0 0 0.125em #666666;
}