.message-btn {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 9px;
  line-height: 11px;
  color: #ffffff;
  letter-spacing: 0.5px;
  border-radius: 100px;
  text-transform: uppercase;
  border: none;
  text-align: center;
  background: #357DBF;
}
.bg-status {
  background: #da7943 !important;
}

.bg-status-success {
  background: #35B7BF !important;
}

.admin-message-contact-card-frequence {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 25px;
  letter-spacing: 0.4px;
  color: #9fa2b4;
}
.fz-14 {
  font-size: 14px !important;
}

.admin-message-contact-card-description,
.admin-message-contact-card-description p,
.admin-message-contact-card-description p span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 28px;
  letter-spacing: 0.4px;
  color: rgba(0, 0, 0, 0.7);
}

.admin-message-contact-card {
  background: #ffffff;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.11);
  border-radius: 5px;
  padding-bottom: 40px;
}

.message-text p {
  color: #000000;
}
.accordion-button {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  width: 2% !important;
}
.accordion-button::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23A81912'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23A81912'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
.admin-content-message,
.admin-content-message p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 131.7%;
  color: rgba(0, 0, 0, 0.65);
}
